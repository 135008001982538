<nav class="nav">
  <div class="container">
    <div class="nav__top-banner">
      <img src="/assets/logo.png" alt="logo" class="nav__img">
      <a href="tel:+61263319499" class="btn btn--animated">Call Now!</a>
    </div>
  </div>
  <div class="nav__menu">
    <ul class="nav__list">
      <li class="nav__item">
        <a routerLink="/" class="nav__link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a>
      </li>
      <li class="nav__item">
        <a routerLink="/services" class="nav__link" routerLinkActive="active">Services</a>
      </li>
      <li class="nav__item">
        <a routerLink="/gallery" class="nav__link" routerLinkActive="active">Gallery</a>
      </li>
      <li class="nav__item">
        <a routerLink="/contact" class="nav__link" routerLinkActive="active">Contact Us</a>
      </li>
    </ul>
  </div>
</nav>
