<footer class="footer">
  <div class="footer__business-details">
    <div class="footer__detail">
      <h6 class="footer__title">Address</h6>
      <div class="footer__text-container">
        <p class="footer__text">14 Wembley Place</p>
        <p class="footer__text">Kelso, NSW 2795</p>
      </div>
    </div>
    <div class="footer__detail">
      <h6 class="footer__title">Contact Details</h6>
      <div class="footer__text-container footer__text-container--contact">
        <p class="footer__text"><strong>PHONE: </strong>02 6331 9499</p>
        <p class="footer__text"><strong>EMAIL: </strong>jake&#64;jakeshydraulics.com.au</p>
      </div>
    </div>
    <div class="footer__detail">
      <h6 class="footer__title">Opening Hours</h6>
      <div class="footer__text-container" style="border-right: none;">
        <p class="footer__text"><strong>Mon - Fri: </strong>7:30am - 5:00pm</p>
        <p class="footer__text"><strong>Sat: </strong>7:30am - 12:00pm</p>
        <p class="footer__text"><strong>Sun: </strong>Emergency Breakdowns</p>
        <!-- <p class="footer__text"><strong>(Call 0417 725 092)</strong></p> -->
      </div>
    </div>
  </div>
  <a href="https://facebook.com/jakesbathurst" target="_blank" rel="noopener noreferrer" class="social">
    <i class="fab fa-facebook-square"></i>
    Follow us on Facebook
  </a>
  <div class="footer__legal">
    <p>&copy; Copyright {{ date }} Jake's Hydraulics &amp; General Engineering</p>
    <p>Proudly designed by <a href="https://www.pauldenmeadwebdesigns.com.au">Paul Denmead Web Designs</a> </p>
  </div>
</footer>
